import React from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { SignIn } from '../auth/SignIn';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  containerFix: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: '600px',
  },
});

// Page wrapper for SignIn Component
function SignInPage(props) {
  const { classes, theme } = props;
  //   const width = useWidth();
  //   const isWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <div className="lg-p-top" style={{ backgroundColor: '#FFFFFF' }}>
      <Typography variant="h3" align="center" className="lg-mg-bottom">
        Sign In
      </Typography>
      <div className={classNames('container-fluid', classes.containerFix)}>
        <SignIn />
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(SignInPage);
