import React from 'react';

export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ config, children }) => {
  return (
    <FirebaseContext.Provider value={{ config }}>
      {children}
    </FirebaseContext.Provider>
  );
};
